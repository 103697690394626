import React from 'react';
import styled from 'styled-components';
import { hover, Transition, Color, breakpoints } from '../../../styles';
import { Link } from '../Links';

const CardWrapper = styled(Link)`
  border: 1px solid #0f1014;
  border-radius: 10px;
  color: ${Color.TRINITY_BLACK};
  text-decoration: none;

  @media print, screen and (min-width: ${breakpoints.xl}em) {
    border: none;
    ${hover('background', 'none', Color.WHITE)};
    ${hover('box-shadow', '0px 0px 0px 1px #0f1014', '20px 20px 30px rgba(15, 16, 20, 0.06);')};
    ${hover('transform', 'scale(1)', 'scale(1.01)')};
    transition: all ${Transition.DEFAULT};
  }
`;

const DefaultCard = ({ className, children, ...rest }) => (
  <CardWrapper className={className} {...rest}>
    {children}
  </CardWrapper>
);

export default DefaultCard;
