import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../../styles';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 40px;

  ${mediaQueries('md')`
    grid-template-columns: repeat(2, 27.3rem);
    grid-column-gap: 40px;
  `};

  ${mediaQueries('xl')`
    grid-template-columns: repeat(3, 27.3rem);
  `};
`;

const CardGrid = ({ children }) => {
  return <Grid>{children}</Grid>;
};

export default CardGrid;
