import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../../styles';

const Section = styled.section`
  ${({ $colorTheme }) =>
    $colorTheme === 'DARK'
      ? css`
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          a {
            color: ${Color.WHITE};
          }
        `
      : null};
`;

const BaseSection = ({ colorTheme, children, ...rest }) => {
  return (
    <Section $colorTheme={colorTheme} {...rest}>
      {children}
    </Section>
  );
};

export default BaseSection;
