import React from 'react';
import styled from 'styled-components';
import { TwitterIcon, LinkedInIcon } from '../../Svg';
import { Color, mediaQueries } from '../../../styles';
import { Link } from '../../Gui/Links';

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  height: 8rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${Color.NEUTRAL_700};

  ${mediaQueries('md')`
    align-items: flex-start;
    height: 7.4rem;
  `};
`;

const SocialIconsWrapper = styled.div`
  margin-right: 27px;

  a {
    &:first-of-type {
      margin-right: 29px;
    }
  }
  a > svg {
    path {
      fill: ${Color.WHITE};
    }
  }

  ${mediaQueries('md')`
    margin: 24px 120px 24px 0;
  `};
`;

const Footer = () => (
  <Wrapper>
    <SocialIconsWrapper>
      <Link url={'https://twitter.com/mcagney'}>{TwitterIcon}</Link>
      <Link url={'https://www.linkedin.com/in/mikecagney/'}>{LinkedInIcon}</Link>
    </SocialIconsWrapper>
  </Wrapper>
);

export default Footer;
