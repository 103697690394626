import React from 'react';
import styled, { css } from 'styled-components';
import { Fonts, mediaQueries } from '../../../styles';
import { DefaultCard } from '../../Gui/Cards';
import { ArticleCardArrow } from '../../Svg';

const MediaCardWrapper = styled(DefaultCard)`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  > svg {
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 24px;
  }

  ${mediaQueries('md')`
    padding: 24px 28px;
    min-height: 26.4rem;
    max-width: 27.3rem;
    
    > svg {
      margin-top: auto;
    }
  `};

  ${mediaQueries('xl')`
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
  `};
`;

const lineClamp = () => {
    return css`display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    ${mediaQueries('md')`
      -webkit-line-clamp: 3;
    `};
    `
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.6rem;
    line-height: calc(23 / 16);
    margin-bottom: 6px;
    min-height: 3.6rem;
    ${lineClamp()}
    ${mediaQueries('md')`
      margin-top: 70px;
    `};
  }

  h2,
  p {
    text-align: left;

    ${mediaQueries('xl')`
      max-width: 16.6rem;
    `};
  }

  p {
    ${Fonts.GOTHIC_ELLIPSIS_WORKAROUND};
    max-width: 80%;
    ${lineClamp()}

    ${mediaQueries('md')`
      max-width: unset;
      display: inline;
    `};

    ${mediaQueries('xl')`
      max-width: 16.6rem;
      display: -webkit-box;
    `};
  }
`;

const ArticleCard = ({ url, title, description }) => (
  <MediaCardWrapper url={url}>
    <TextWrapper>
      <h2>{title}</h2>
      <p>{description}</p>
    </TextWrapper>
    {ArticleCardArrow}
  </MediaCardWrapper>
);

export default ArticleCard;
